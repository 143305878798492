
export default {
  name: 'b-dropdown-click',
  props: {
    item: {
      type: Object,
      default: () => [],
      required: true,
    },
    menuClass: {
      type: String,
      required: false,
    },
  },
};
