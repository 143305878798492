
  import topTableInit from '../plugins/topTableInit';
  import {
    validateInputImportMixin,
    autocompleteImportMixin,
    multiselectImportMixin,
  } from '../plugins/mpowerInputsInit';

  export default {
    name: 'MPowerTableClient',
    mixins: [topTableInit, validateInputImportMixin, autocompleteImportMixin, multiselectImportMixin],
    mounted() {
      this.$nextTick(() => {
        if (this.$refs.table?.$el instanceof HTMLElement) {
          const table = this.$refs.table.$el.querySelector('table');
          if (table && this.$attrs.customClass) {
            table.parentNode.classList.add(this.$attrs.customClass);
          }
        }
      });
    },
  };
