
  import { checkLocationDecorationPartPrices, feedSamplePrices } from '~/utils/productPrices';
  import productPrices from '../../plugins/productPrices';
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        isLoadingParts: false,
        mainPart: this.selectedPart,
        defValue: this.part,
        optionsVL: [this.selectedPart],
      };
    },
    components: {
      MPowerAutocomplete: () => import('@/components/MPowerAutocomplete.vue'),
      InventoryStatus: () => import('./InventoryStatus'),
      PartsColor: () => import('./PartsColor'),
    },
    mixins: [productPrices],
    props: {
      parts: {
        type: Array,
        default: () => [],
      },
      partGroup: {
        type: String,
      },
      orderData: {
        type: Object,
      },
      configureProduct: {
        type: Object,
      },
      productData: {
        type: Object,
      },
      canSkipPricing: {
        type: Boolean,
        default: false,
      },
      showAdditionalInfo: Boolean,
      hideDescription: Boolean,
      hidePartId: Boolean,
      hideSKU: Boolean,
      inventoryAtBottom: Boolean,
      selectedPart: Object,
    },
    async mounted() {
      if (!this.productData.loadedAllMainParts) {
        this.productData.loadedAllMainParts = true;
        try {
          this.loading = true;
          // const params = {
          //   project: {},
          //   supplierId: { _id: this.productData.supplierId._id, isEQP: this.productData.supplierId.isEQP },
          //   category: this.productData.category
          //     ? { shippingPackages: this.productData.category.shippingPackages }
          //     : null,
          //   query: { status: { $ne: 'discontinued' } },
          //   currencyRate: this.storeInformation.currencyRate,
          // };
          // if (this.productData.status !== 'active') {
          //   params.query = {};
          // }
          // if (this.productData.decorationProducts && this.productData.decorationProducts[0]) {
          //   params.decorationProducts = this.productData.decorationProducts.map((el) => ({
          //     _id: el._id,
          //     parts: el.parts.map(({ _id, pricing }) => ({ _id, pricing })),
          //   }));
          // }
          // const parts = await this.$api.products.loadAllRemainParts(this.productData._id, params);
          // const selectedParts = [...this.productData.parts];
          if (
            this.productData.hasDecorationProducts &&
            !this.productData.parts.find((e) =>
              e.pricing.find((e) => e.configurationType === 'Decorated' && e.originPrice)
            )
          ) {
            // console.log(this.productData);
            // location.reload(); //force to reload all product sinse decorated prices was not loaded
          }
          // for (let i = 0; i < parts.length; i++) {
          //   let hasPart = false;
          //   for (let j = 0; j < selectedParts.length; j++) {
          //     if (parts[i]._id === selectedParts[j]._id) {
          //       hasPart = true;
          //       if (!selectedParts[j].mediaList) selectedParts[j].mediaList = [];
          //       const exist = parts[i].mediaList.map((e) => e._id);
          //       if (parts[i].mediaList)
          //         selectedParts[j].mediaList.push(
          //           ...parts[i].mediaList.filter((e) => exist.includes(e._id) && !mediaHasClassTypeName(e, 'Logo'))
          //         );
          //       // Object.assign(selectedParts[j], parts[i]);
          //       // if (selectedParts[j].ApparelSize) selectedParts[j].image = null;
          //       break;
          //     }
          //   }
          //   if (!hasPart) {
          //     this.productData.parts.push(parts[i]);
          //   }
          // }
          checkLocationDecorationPartPrices(this.productData);
          feedSamplePrices(this.productData.parts, this.productData, this.$store.getters.storeInformation);
        } catch (e) {
          console.log(e);
        } finally {
          this.$nextTick(() => {
            this.$emit('loadedMoreParts');
          });
          this.loading = false;
        }
      }
      this.$nextTick(() => {
        this.$emit('inited');
      });
    },

    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
      }),
      colorsAutocompletePath() {
        const basePath = `/products/${this.productData._id}/parts/by-unique-color`;
        const queries = [
          `except=${this.selectedPart.colorName}`,
          +this.partGroup > 1 ? `partGroup=${this.partGroup}` : null,
          this.productData.supplierId.hasNotApparelSize && !this.selectedPart.ApparelSize?.label_size
            ? `groupByPartId=${1}`
            : null,
        ].filter((q) => !!q);
        return `${basePath}?${queries.join('&')}`;
      },
    },
    methods: {
      async chooseColor(part, isLoaded, currentImageIndex = undefined) {
        if (!part) return;
        if (!isLoaded) await this.loadParts(part);
        const selectedPart = this.productData.parts.find((p) =>
          part._id ? p._id === part._id : part.partId === p.partId
        );
        this.$emit('chooseColor', selectedPart, currentImageIndex);
        if (this.configureProduct?.showAllProductsImages) {
          this.mainPart = selectedPart;
        }
        return selectedPart;
      },
      async loadParts(part) {
        const { partId, partGroup } = part;
        if (+partGroup > 1) {
          const ids = this.productData.parts.map(({ _id }) => _id);
          if (part._id && !ids.includes(part._id)) {
            this.productData.parts.push(part);
          }
        } else {
          let lastDecoration;
          try {
            lastDecoration = JSON.parse(localStorage.getItem('lastDecoration'));
          } catch (e) {}
          try {
            this.isLoadingParts = true;
            const locations = this.productData.parts.find((e) => e.partId === partId)?.locations;
            const { parts, ...rest } = await this.$api.products.getProductBySlug(this.productData.slug, {
              partId,
              ...(lastDecoration || {}),
              ...((locations?.length && { locations }) ||
                (this.productData?.selectedDecoration?.location?._id && {
                  locationId: this.productData?.selectedDecoration?.location?._id,
                })),
            });
            if (locations?.length) {
              Object.assign(this.productData, rest);
              // console.log(this.productData.locations.map((e) => e.locationId));
            }
            const ids = this.productData.parts.map(({ _id }) => _id);
            this.productData.parts.push(...parts.filter((p) => !ids.includes(p._id)));
          } catch (e) {
            console.log(e);
          } finally {
            this.isLoadingParts = false;
          }
        }
        checkLocationDecorationPartPrices(this.productData);
        feedSamplePrices(this.productData.parts, this.productData, this.$store.getters.storeInformation);

        this.$nextTick(() => this.$emit('loadedMoreParts'));
      },
    },
  };
