
  import { mapGetters } from 'vuex';
  import dataLayerMixin from '@/plugins/dataLayer.mixin.js';

  const sleep = () => new Promise((resolve) => setTimeout(resolve, 300));
  import { SearchHistory } from '@/lib/SearchHistory';

  function sanitize(text) {
    return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  }

  function escapeRegExp(str) {
    return str.replace(/([.*+?=^!:${}()|[\]\/\\]+)/g, '\\$1');
  }

  export default {
    name: 'GlobalSearch',
    mixins: [dataLayerMixin],
    props: {
      search: {
        type: Object,
      },
      mobile: {
        type: Boolean,
        default: false,
      },
      productsList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        minChars: 1,
        isFocused: false,
        loadingSearch: false,
        searchQuery:
          this.$route.query.query ||
          (this.productsList?.length &&
            decodeURIComponent(
              (this.$route.params.pathMatch &&
                this.$route.params.pathMatch.split('/').length === 1 &&
                this.$route.fullPath.includes('/search') &&
                this.$route.params.pathMatch.replace('search/', '')) ||
                ''
            )) ||
          '',
        products: [],
        categories: [],
        facets: [],
        suppliers: [],
        designs: [],
        themes: [],
        blogs: [],
        facetsCategories: [],
      };
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
        customer: 'auth/loggedInUser',
      }),
      searchHistory() {
        return new SearchHistory();
      },
      isCloseoutSearch() {
        const query = this.searchQuery.toLowerCase().replace(new RegExp('\\\\', 'g'), '\\\\');
        return query && 'closeout'.includes(query);
      },
      hasSearchResults() {
        return !!(
          this.loadingSearch ||
          this.categories.length ||
          this.facets.length ||
          this.products.length ||
          this.suppliers.length ||
          this.designs.length ||
          this.facetsCategories.length ||
          this.themes.length ||
          this.blogs.length ||
          this.isCloseoutSearch
        );
      },
    },
    mounted() {
      if (!!document.querySelector('.no-search-results')) {
        this.searchQuery = '';
      }
    },
    methods: {
      addToHistory(query) {
        this.searchHistory.add(query);
      },
      goToSearch({ text, link }) {
        if (link) {
          this.$router.push({ path: link, query: { page: 1 } });
          return;
        }
        const query = encodeURIComponent(text);
        this.$router.push({ path: `/products/search/${query}`, query: { page: 1 } });
        this.isFocused = false;
      },
      escapedQuery(query) {
        return escapeRegExp(sanitize(query || ''));
      },
      getEscapedPart(str) {
        const text = sanitize(str);
        if (this.searchQuery.length === 0) {
          return text;
        }
        const query = / /g.test(str) ? this.searchQuery : this.searchQuery.replace(/ /g, '');
        return str.split(new RegExp(this.escapedQuery(query), 'gi'));
      },
      highlight(str) {
        try {
          const res = this.getEscapedPart(str);
          return `<strong>${res[0]}</strong>${str
            .replace(res[0], '')
            .replace(new RegExp(this.escapedQuery(res[1]) + '$'), '')}<strong>${res[1]}</strong>`.replace(
            'undefined',
            ''
          );
        } catch (e) {
          return str;
        }
      },
      async handleBlur(evt) {
        const tgt = evt.relatedTarget | evt.target;
        if (tgt) {
          let a = tgt;
          while (a) {
            if (a.classList && a.classList.contains('vbt-autocomplete-list')) {
              return;
            }
            a = a.parentNode;
          }
        } else {
          await sleep();
        }
        this.isFocused = false;
      },
      async enterSearch() {
        if (this.searchQuery) {
          await this.productsQuickSearch(this.searchQuery);
          this.searchHistory.add({ text: this.searchQuery });
          const query = encodeURIComponent(this.searchQuery);
          const category = this.categories.find((cat) => this.getEscapedPart(cat.name).every((r) => r === ''));
          const { searchDesigns } = this.storeInformation;
          if (searchDesigns) {
            this.$router.push(`/designs?title=${query}`);
          } else if (this.products.length === 1 && this.searchQuery === this.products[0].productId) {
            this.$router.push(`/products/${this.products[0].slug}`);
          } else if (category) {
            this.$router.push(`/cat/${category.slug}`);
          } else
            this.$router.push(
              `/products/search?query=${query}&token=${this.$axios.defaults.headers.common['searchToken']}`
            );
        }
      },
      onSearch() {
        const { searchSuggestions } = this.storeInformation;
        // this.searchQuery = this.searchQuery.replace(/[^a-zA-Z0-9\- ]/g, '');
        if (this.dealySearch) {
          clearTimeout(this.dealySearch);
        }
        this.dealySearch = setTimeout(async () => {
          const newVal = this.searchQuery;
          if (newVal.length > this.minChars) {
            this.loadingSearch = true;
            const functions = [
              { name: 'productsQuickSearch', short: 'products' },
              { name: 'facetsSearch', short: 'facets' },
              { name: 'facetsCategoriesSearch', short: 'facetsCategories' },
              { name: 'suppliersSearch', short: 'suppliers' },
              { name: 'themesSearch', short: 'themes' },
              { name: 'designsSearch', short: 'designs' },
              { name: 'blogsSearch', short: 'blogs' },
            ]
              .filter((i) => searchSuggestions && searchSuggestions[i.short])
              .map((i) => i.name);
            await Promise.all(functions.map((i) => this[`${i}`](newVal)));
            this.loadingSearch = false;
            this.onDataLayerSearch(newVal);
          }
        }, 500);
      },
      async productsQuickSearch(query) {
        const { data, categories, token } = await this.$api.stores.productsQuickSearch(
          this.$store.getters.storeInformation._id,
          Object.assign({}, { query })
        );
        this.$axios.defaults.headers.common['searchToken'] = token;
        this.products = data;
        this.categories = categories;
        this.$store.dispatch('auth/searchToken', {
          searchToken: token,
        });
      },
      async facetsSearch(query) {
        const req = {
          storeId: this.$store.getters.storeInformation._id,
          query,
        };
        this.facets = await this.$api.facets.facetsSearch(req, true);
      },
      async facetsCategoriesSearch(query) {
        const req = {
          storeId: this.$store.getters.storeInformation._id,
          query,
        };
        this.facetsCategories = await this.$api.facets.facetsCategoriesSearch(req);
      },
      async suppliersSearch(query) {
        const req = {
          storeId: this.$store.getters.storeInformation._id,
          query,
        };
        console.time('suppliers');
        this.suppliers = await this.$api.suppliers.suppliersSearch(req, true);
        console.timeEnd('suppliers');
      },
      async themesSearch(query) {
        this.themes = await this.$api.stores.themesSearch(this.$store.getters.storeInformation._id, query);
      },
      async designsSearch(query) {
        this.designs = await this.$api.stores.designsSearch(this.$store.getters.storeInformation._id, query);
      },
      async blogsSearch(query) {
        this.blogs = await this.$api.stores.blogsSearch(this.$store.getters.storeInformation._id, query);
      },
    },
  };
