
  import { mapGetters } from 'vuex';
  import { calcOrderTotal, calcPOPartTotal } from '../plugins/cartItemsMixin';
  export default {
    components: {
      InventoryStatus: () => import('./productView/InventoryStatus'),
      CartItemsViewPartPrice: () => import('./CartItemsViewPartPrice'),
    },

    props: {
      order: {
        type: Object,
        required: true,
      },
      hideTotals: Boolean,
    },
    methods: {
      calcOrderTotal,
      calcPOPartTotal,
    },

    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
        storeCurrencySign: 'storeCurrencySign',
      }),
      typeSets() {
        return this.order.LineItemArray.map((e) =>
          e.Configuration[0].LocationArray.map(
            (l) => (l.additional && l.DecorationArray.map((d) => d.Artwork?.TypesetArray).flat()) || []
          )
            .flat()
            .filter((e) => e)
        )
          .flat()
          .filter((t) => !!t.value)
          .map((t) => (t.label ? `${t.label}: ${t.value}` : t.value));
      },
      productDiscounts() {
        return [this.order, ...(this.order.childPos || [])]
          .map((el) => parseFloat(el.productDiscount))
          .reduce((cur, next) => parseFloat(cur) + parseFloat(next), 0);
      },
      additionalLocations() {
        return this.order.LineItemArray[0]?.Configuration[0].LocationArray.filter((e, i) => e.additional);
      },
    },
  };
