import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      storeInformation: 'storeInformation',
    }),
  },
  methods: {
    isFreeShipping(total) {
      return this.storeInformation.freeShippingAmount && total >= this.storeInformation.freeShippingAmount;
    },
  },
};
