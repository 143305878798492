import { render, staticRenderFns } from "./CreateAccount.vue?vue&type=template&id=7a418ea2"
import script from "./CreateAccount.vue?vue&type=script&lang=js"
export * from "./CreateAccount.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/codebuild/output/src86562433/src/client/components/Loader.vue').default,MPowerValidateInput: require('/codebuild/output/src86562433/src/client/components/MPowerValidateInput.vue').default,ReferenceField: require('/codebuild/output/src86562433/src/client/components/referenceField/ReferenceField.vue').default})
