import Vue from 'vue';
const topTableImportMixin = {
  data() {
    return {
      topTableLoaded: false,
    };
  },
  async created() {
    if (process.client && !this.topTableLoaded) {
      const TopTable = await import('@mtntop/utils/src/plugins/topTableInit');
      Vue.use(TopTable.default);
      setTimeout(() => (this.topTableLoaded = true));
    }
  },
};
export { topTableImportMixin as default };
